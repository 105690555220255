.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.formContainer {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    text-align: center;
}

.input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 20px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

h2 {
    margin-bottom: 20px;
    color: #333;
}

.popup {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
    text-align: center;
    z-index: 10;
}

.popupContent h3 {
    margin-bottom: 20px;
}

.popupContent button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.popupContent button:hover {
    background-color: #0056b3;
}

.loginLink {
    margin-top: 10px;
}

.input:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

.button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.disabledLink {
    color: #999;
    cursor: not-allowed;
    text-decoration: none;
}
