.NavbarItems {
    background-color: #2d3e50;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
  }
  
  .menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
  }
  
  .nav-links:hover {
    background-color: #1f2a38;
    border-radius: 4px;
  }
  
  .fa-bars, .fa-times {
    color: white;
  }
  
  /* Untuk mobile view */
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #2d3e50;
    padding-top: 20px;
    transition: transform 0.5s ease;
  }
  
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 6px;
    margin: auto;
    border-radius: 4px;
    width: 100%;
    background: #007bff;
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
  }
  
  .nav-links-mobile:hover {
    background-color: #0056b3;
  }
  
  @media screen and (width <= 768px) {
    .menu-icon {
      display: block;
    }
  
    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      background-color: #2d3e50;
      transition: transform 0.5s ease;
    }
  
    .nav-menu.active {
      left: 0;
      transition: transform 0.5s ease;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;

        /* padding: 1rem; */
        margin: auto;
        margin-top: 20px;
        border-radius: 4px;
        width: 50%;
        background: #007bff;
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
        cursor: pointer;
      }
      
      .nav-links-mobile:hover {
        background-color: #0056b3;
      }
  }
  
    @media screen and (width >= 961px) {
    .nav-menu {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      position: static;
      background-color: transparent;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;

        /* padding: 1rem; */
        margin: auto;
        border-radius: 4px;
        width: 100%;
        background: #007bff;
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
        cursor: pointer;
      }
      
      .nav-links-mobile:hover {
        background-color: #0056b3;
      }
    }
  


