.college-photoshoot-data{
    margin: 8rem 11rem;
    color: #2a2a2a;

}

.college-photoshoot-data h1{
    font-size: 3rem;
}

.college-photoshoot-data-card{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.cp-card{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgb(0 0 0 / 50%);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.college-photoshoot-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.college-photoshoot-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.college-photoshoot-image:hover img{
    transform: scale(1.3);
}

.cp-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem;
}


@media screen and (width <=850px){
    .college-photoshoot-data{
        margin: 8rem 4rem;
    }

    .college-photoshoot-data-card{
        flex-direction: column;
    }

    .cp-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }
}