/* General button styling */
.photoshoot-link {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
}

.photoshoot-link:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Icon inside the button */
.photoshoot-link svg {
    font-size: 1.2rem;
}

.product{
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.product h1{
    font-size: 3rem;
    text-align: center;
}

.product p{
    text-align: center;
}

.product-card{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.p-card{
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgb(0 0 0 / 50%);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.product-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.product-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.product-image:hover img{
    transform: scale(1.3);
}

.p-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem;
}

.p-card {
    padding: .2rem 0 .1rem;
    margin-top: 20px;
    width: 100%;
    margin: 4rem 2rem;
}

/* Responsive for mobile */
@media screen and (width <= 850px) {
    .product {
        margin: -4rem -2rem;
        margin-bottom: 100px;
    }

    .product-card {
        flex-direction: column;
    }

    .p-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .p-card p {
        width: 86%;
        text-align: justify;
        margin-bottom: 1.5rem;
    }
}
