.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 20px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

h2 {
    margin-bottom: 20px;
    color: #333;
}
