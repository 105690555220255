.about-container{
    margin: 4rem 6rem;
    text-align: start;
}

.about-container h1{
    padding-bottom: 1rem;
}

.about-container p{
    padding-bottom: 2rem;
}

@media screen and (width <= 850px){
    .about-container{
        margin: 4rem 2rem;
    }
}