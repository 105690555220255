/* General body and HTML settings */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: Arial, sans-serif;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Navbar styling */
  .NavbarItems {
    background-color: #f8b809;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
  }
  
  .navbar-logo {
    font-size: 2rem;
    color: #222;
    cursor: pointer;
  }
  
  /* Popup overlay */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 50%);
    z-index: 1000;
  }
  
  .popupContent {
    width: 90%;
    max-width: 450px;
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
    overflow-y: auto;
    max-height: 80vh;
  }
  
  .popupContent h3 {
    font-size: 1.4em;
    margin-bottom: 25px;
  }
  
  .button-container button {
    margin: 10px 0;
    padding: 15px;
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 1.1em;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }
  
  .button-container button:focus {
    outline: none;
  }
  
  /* Layar (hero section) styling */
  .layar {
    width: 100%;
    text-align: center;
    background-size: cover;
    padding: 80px 20px;
    box-sizing: border-box;
    margin-top: -100px; /* To avoid overlap with fixed navbar */
    margin-bottom: 100px; /* To avoid overlap with fixed navbar */
  }
  
  .layar img {
    max-width: 100%;
    height: auto;
  }
  
  .layar h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .layar p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .layar button {
    padding: 15px 30px;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .layar button:hover {
    background-color: #0056b3;
  }
  
  /* Footer styling */
  .footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem;
  }
  
  /* Responsive design */
  @media screen and (width <= 768px) {
    .popupContent {
      width: 95%;
      padding: 20px;
    }
  
    .popupContent h3 {
      font-size: 1.3em;
    }
  
    .button-container button {
      padding: 12px;
      font-size: 1rem;
    }

    /* .popupContentMobile {
        width: 95%;
        padding: 20px;
      }
    
      .popupContentMobile h3 {
        font-size: 1.3em;
      }
    
      .button-containerMobile button {
        padding: 12px;
        font-size: 1rem;
      } */
  
    .layar h1 {
      font-size: 2rem;
    }
  
    .layar p {
      font-size: 1rem;
    }
  
    .layar button {
      padding: 12px 24px;
      font-size: 1rem;
    }

    .layar {
        width: 100%;
        text-align: center;
        background-size: cover;
        padding: 80px 20px;
        box-sizing: border-box;
        margin-top: 40px; /* To avoid overlap with fixed navbar */
        margin-bottom: 100px; /* To avoid overlap with fixed navbar */
      }
  }
  
  @media screen and (width <= 480px) {
    .popupContent {
      padding: 15px;
    }
  
    .popupContent h3 {
      font-size: 1.2em;
    }
  
    .button-container button {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .layar h1 {
      font-size: 1.8rem;
    }
  
    .layar p {
      font-size: 0.9rem;
    }
  
    .layar button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
  