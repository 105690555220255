.layar{
    width: 100%;
    height: 100vh;
    position: relative;

    /* background-color: #fb9d06; */

    /* background-color: #f2ac3bb0; */
}

.layar-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.layar-mid h1{
    padding-top: 4rem !important;
}

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.layar-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.layar-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: #313;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layar-text p{
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem;
}

.layar-text .show{
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
}

.layar-text .hide{
    display: none;
}

@media screen and (width <= 555px){
    .layar-text h1{
        padding: 10px 20px;
    }

    .layar-text p{
        font-size: 1.1rem;
        padding: 0 0 1rem;
        margin-bottom: 1px;
        margin-top: -20px;
    }

    .layar-text{
        top: 65%;
    }
    
    img{
        width: 100%;
        height: 67%;

        /* object-fit: cover; */
    }

    .layar-text .show{
        padding:  0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}