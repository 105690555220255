body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: linear-gradient(180deg, rgb(249 160 6) 0%, rgb(255 255 255 / 100%) 100%);
}

/* .division{
    margin: 4rem 6rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for a smoother look */
/* } */ 

.division {
    margin: 4rem 6rem;
    padding: 2rem;
    background-color: rgb(250 181 62 / 99.6%);
    border-radius: 10px;
}


/* .division-c{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}

.division-d{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}

.division-e{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}

.division-f{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}

.division-g{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}

.division-h{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-i{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-j{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-k{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-l{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-m{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-n{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-o{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-p{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-q{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-r{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
}
.division-s{
    background-color: rgba(250, 181, 62, 0.996);
    box-shadow: 1px 1px 8120px rgba(250, 181, 62, 0.996);
} */

.division h1{
    font-size: 3rem;
    text-align: center;
}

.division-p p{
    text-align: center;
}


/* .first-description{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: justify;
    margin-top: 4.5rem;
    border-radius: 5pc;
} */

.first-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;

    /* background-color: rgba(255, 255, 255, 0.9); White background for contrast */
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
}

/* .first-description-reverse {
    display: flex;
    text-align: justify;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

} */

.first-description-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;

    /* background-color: rgba(255, 255, 255, 0.9); White background for contrast */
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
}



/* .first-description-reverse{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
} */

.description-text{
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}

.description-text h2{
    padding-bottom: 1rem;

        /* Adding white shadow */
    text-shadow: 2px 2px 8px rgb(255 255 255 / 90%); 
}

.image{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: 99;
}

.image img{
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;

    /* box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19); */

        /* Adding white shadow */
    box-shadow: 1px 1px 8120px rgb(250 181 62 / 99.6%); 
}

.image img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (width <= 850px){
    .division{
        margin: 4rem 2rem;
    }

    .first-description{
        display: flex;
        flex-direction: column-reverse;

        /* align-items: center;
        justify-content: space-between; */
        margin-top: 2.5rem;
    }

    .first-description-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4.5rem;

        /* border-radius: 5pc; */

        /* background-color: rgba(255, 255, 255, 0.9); White background for contrast */
    }

    .description-text{
        width: 100%;
    }

    .image{
        width: 100%;
        margin: 1.8rem 0;
        z-index: auto;
    }

    .image img{
        height: 250px;
        box-shadow: 1px 1px 8120px rgb(250 181 62 / 99.6%); 
    }
}
